import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Form } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux/es/exports';

import BrandImage from '../../assets/images/sunrise.png';
import Account from '../../assets/icons/profile.png';
import Cart from '../../assets/icons/shopping-cart.png';
import Chevrone from '../../assets/icons/chevron.svg';
import { getCategoriesAsync } from '../../redux/categorySlice';
import NavSkeletons from '../Skeletons/NavSkeletons';
import LoaderComponent from '../LoaderComponent';
import { getSubscriberDetailsAsync } from '../../redux/subscriberSlice';

const Navbar = () => {
  const [showLoginModal, setLoginModalShow] = useState(false);
  const navigate = useNavigate();
  const handleLoginModalClose = () => setLoginModalShow(false);
  const handleLoginModalShow = () => setLoginModalShow(true);

  const [loginBox, setLoginBox] = useState(true);

  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.category);
  const loading = useSelector((state) => state.categories.loading);
  const { user, userDetails } = useSelector((state) => state.subscribers);
  const { cartItems } = useSelector((state) => state.cart);

  const [searchKey, setSearchKey] = useState('');
  useEffect(() => {
    if (user) {
      dispatch(getSubscriberDetailsAsync());
    }
  }, [user]);
  useEffect(() => {
    dispatch(getCategoriesAsync());
  }, [dispatch]);

  if (loading) return <NavSkeletons />;
  const login = true;

  return (
    <>
      <Container>
        <section className='topnavInfo'>
          <div className='topnavInfo-left'>
            <i className='fa fa-phone'></i> 545644848489 &nbsp;&nbsp; |
            &nbsp;&nbsp;&nbsp;
            <i className=' fa fa-envelope'></i> info@sunrisetradelink.com.no
          </div>
          {userDetails && (
            <div className='topnavInfo-right'>{`Welcome ${userDetails?.name}`}</div>
          )}
        </section>
      </Container>
      <section className='navMainWrapper'>
        <Container>
          <nav className='navWrapper'>
            <figure className='navWrapper-brand'>
              <Link to='/'>
                <h1 style={{ fontWeight: 'bold' }}>SIKHAR TRADERS</h1>
                {/* <img src={BrandImage} alt='' /> */}
              </Link>
            </figure>
            <section className='navWrapper-search'>
              <input
                type='search'
                name='search'
                value={searchKey}
                onKeyUp={(e) =>
                  e.key === 'Enter' && navigate('/search', { state: searchKey })
                }
                id=''
                onChange={(e) => setSearchKey(e.target.value)}
                placeholder='search here ...'
              />
              <button
                className='bton bton--sm bton--primary ms-1'
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/search', { state: searchKey });
                }}
              >
                <i className='fa fa-search'></i>{' '}
                <span className='mobsearch'>search</span>
              </button>
            </section>
            <section className='navWrapper-carts'>
              {user ? (
                <span className='navWrapper-carts-login'>
                  <Link to='/account'>
                    <button className='bton bton--nacked'>
                      <img src={Account} alt='' /> My Account
                    </button>
                  </Link>
                </span>
              ) : (
                <span className='navWrapper-carts-login'>
                  <Link to='/login'>
                    <button className='bton bton--nacked'>
                      <i className='fa fa fa-sign-in'></i> Login
                    </button>
                  </Link>
                </span>
              )}

              <span className='myCart'>
                <Link to='/cart'>
                  <img src={Cart} alt='' /> &nbsp; <span>My Cart</span>
                  <div className='cartCount'>{cartItems?.length}</div>
                </Link>
              </span>
            </section>
          </nav>
        </Container>
        <section>
          <nav className='navbarBox'>
            <Container>
              <ul className='menu p-0 m-0'>
                {categories &&
                  categories.map((cat) => {
                    const data = {
                      cat_id: cat._id,
                      subData: null,
                      sub2Data: null,
                    };
                    return (
                      <>
                        <li className='menu--items'>
                          <Link
                            to={`/category/${cat.slug}`}
                            state={data}
                            className='navitems'
                          >
                            {cat.name}{' '}
                          </Link>
                          {cat.subCategory.length > 0 && (
                            <img src={Chevrone} alt='' />
                          )}
                          {cat.subCategory.length > 0 && (
                            <section className='sub--menu'>
                              {cat.subCategory &&
                                cat.subCategory.map((sub) => {
                                  const data = {
                                    cat_id: cat._id,
                                    subData: { _id: sub._id, name: sub.name },
                                    sub2Data: null,
                                  };
                                  return (
                                    <section className='subcatWrapper'>
                                      <div className='subcatWrapper--title'>
                                        <Link
                                          to={`/category/${cat.slug}`}
                                          state={data}
                                        >
                                          {sub.name}
                                        </Link>
                                      </div>
                                      <ul className='subcatWrapper--itemholder'>
                                        {sub.subCategory2 &&
                                          sub.subCategory2.map((sub2) => {
                                            const data = {
                                              cat_id: cat._id,
                                              subData: {
                                                _id: sub._id,
                                                name: sub.name,
                                              },
                                              sub2Data: {
                                                _id: sub2._id,
                                                name: sub2.name,
                                              },
                                            };
                                            return (
                                              <li className='subcatWrapper--itemholder__items'>
                                                <Link
                                                  to={`/category/${cat.slug}`}
                                                  state={data}
                                                >
                                                  {sub2.name}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </section>
                                  );
                                })}
                            </section>
                          )}
                        </li>
                      </>
                    );
                  })}
                <li className='menu--items'>
                  <Link className='navitems' to={`/cleaning`}>
                    Cleaning Facilities
                  </Link>
                </li>
              </ul>
            </Container>
          </nav>
        </section>
      </section>

      <section className='mobileWrapper'>
        <section className='mobileNavbar'>
          <section>
            <NavLink
              className={(navData) =>
                navData.isActive ? 'mobActiveTab' : 'notActive'
              }
              to='/'
            >
              <section className='mobileNavbar-items'>
                <i className='fa fa-home'></i>
                <div className='mobileNavbar-items-name'>home</div>
              </section>
            </NavLink>
          </section>
          <section>
            <NavLink
              className={(navData) =>
                navData.isActive ? 'mobActiveTab' : 'notActive'
              }
              to='/mobcat'
            >
              <section className='mobileNavbar-items'>
                <i className='fa fa-sliders'></i>
                <div className='mobileNavbar-items-name'>Categories</div>
              </section>
            </NavLink>
          </section>
          <section>
            <NavLink
              className={(navData) =>
                navData.isActive ? 'mobActiveTab' : 'notActive'
              }
              to='/cart'
            >
              <section className='mobileNavbar-items'>
                <i
                  className='fa  fa-shopping-cart
'
                ></i>
                <div className='mobileNavbar-items-name'>Cart</div>
                {cartItems.length > 0 && (
                  <div className='cartMobCount'>{cartItems.length}</div>
                )}
              </section>{' '}
            </NavLink>
          </section>
          <section>
            <NavLink
              className={(navData) =>
                navData.isActive ? 'mobActiveTab' : 'notActive'
              }
              to='/more'
            >
              <section className='mobileNavbar-items'>
                <i
                  className='fa  fa-gear
'
                ></i>
                <div className='mobileNavbar-items-name'>more</div>
              </section>{' '}
            </NavLink>
          </section>
        </section>
      </section>
    </>
  );
};

export default Navbar;
