import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AboutClean from '../../assets/images/cleaning/Aboutclean.png';

const About = () => {
  return (
    <div>
      <Container>
        <section className='homeCleanWrapper'>
          <Row>
            <Col md={6}>
              <h1 className='homeCleanWrapper-title'>
                Sunrise cleaning facility
              </h1>
              <p className='homeCleanWrapper-box'>
                We have a 5 years experience with cleaning please ask me for
                references We offer “ fornøyd -garanti” if you’re not happy with
                the result We will come back within 48 hours to finish and make
                sure you’re satisfied with the job . we provide house cleaning
                Moving-related cleaning Deep clean Office and workplace cleaning
                Standard cleaning Warehouse cleaning School / college cleaning
                Window cleaning Floor treatment Main cleaning Daily cleaning
                Hygiene articles Hotel/restaurants cleaning facilities
              </p>
              <Link to='/cleaning'>
                <button className='bton bton--md bton--primary mb-2'>
                  View Services
                </button>
              </Link>
              {/* <ul className='homeCleanWrapper-box'>
                <li>house cleaning </li>
                <li>Moving-related cleaning </li>
                <li>Deep clean </li>
                <li>Office and workplace cleaning </li>
                <li>Standard cleaning </li>
                <li>Warehouse cleaning </li>
                <li>School / college cleaning </li>
                <li>Window cleaning </li>
                <li>Floor treatment </li>
                <li>Main cleaning </li>
                <li>Daily cleaning </li>
                <li>Hygiene articles </li>
                <li>Hotel/restaurants cleaning </li>
              </ul> */}
            </Col>
            <Col md={6}>
              <figure className='homeCleanWrapper-image'>
                <img src={AboutClean} alt='' />
              </figure>
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
};

export default About;
