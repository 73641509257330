import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import paymentAcceptance from '../../assets/images/payment.png';

const Footer = () => {
  return (
    <>
      <div className='movfooter'></div>
      <section role='Footer'>
        <Container>
          <Row className='justify-content-between'>
            <Col md={3}>
              <section className='foooterInfo'>
                <header className='Links-title'>
                  <h4>About Sunrise</h4>
                </header>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Fugiat, maxime illo dignissimos enim, magni non rem
                  exercitationem beatae, minus quod odio cum possimus quisquam
                  ipsum ea eligendi! Vero, fugit quaerat.
                </p>
              </section>
            </Col>
            <Col md={2}>
              <section className='Links'>
                <header className='Links-title'>
                  <h4>Links</h4>
                </header>

                <ul className='Links-box'>
                  <li className='Links-box-item'>
                    {' '}
                    <Link to='/cleaning'> </Link> Cleaning Facility
                  </li>

                  <li className='Links-box-item'>
                    {' '}
                    <Link to='/'> </Link> Terms and conditons
                  </li>
                  <li className='Links-box-item'>
                    {' '}
                    <Link to='/'> </Link> Privacy Policy
                  </li>
                </ul>
              </section>
            </Col>
            <Col md={3}>
              <section className='contact'>
                <header className='contact-title'>
                  <h4>Customer Support</h4>
                </header>

                <ul className='contact-box'>
                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-map-marker'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Location</strong>{' '}
                      </div>
                      <div className='addHolder-value '>
                        Storgata 15 0161 Oslo
                      </div>
                    </div>
                  </li>
                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-phone'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Phone</strong>{' '}
                      </div>
                      <div className='addHolder-value '>545644848489 </div>
                    </div>
                  </li>

                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-envelope'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Email</strong>{' '}
                      </div>
                      <div className='addHolder-value '>
                        <a href='mailto:paichopasal@gmail.com'>
                          <span style={{ color: 'white' }}>
                            sample@gmail.com{' '}
                          </span>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </section>
            </Col>
            <Col md={3}>
              <section className='socialMedia'>
                <header className='socialMedia-title'>
                  <h4>Find Us On</h4>
                </header>

                <section className='socialWrapper'>
                  <a href={''} target='_blank' rel='noreferrer noopener'>
                    <div className='socialWrapper-icons'>
                      <i className='fa fa-facebook'></i>
                    </div>
                  </a>

                  <a href={''} target='_blank' rel='noreferrer noopener'>
                    <div className='socialWrapper-icons'>
                      <i className='fa fa-instagram'></i>
                    </div>
                  </a>
                  <a href={''} target='_blank' rel='noreferrer noopener'>
                    <div className='socialWrapper-icons'>
                      <i className='fa fa-twitter'></i>
                    </div>
                  </a>
                </section>

                <header className='socialMedia-title'>
                  <h4>We Accept</h4>
                </header>
                <img
                  src={paymentAcceptance}
                  alt=''
                  style={{ width: '10rem', marginTop: '1rem' }}
                />
              </section>
            </Col>
          </Row>

          <hr />
          <section className='copyright'>
            Sunrise multitradelink &copy; {new Date().getFullYear()} All Rights
            Reserved
          </section>
        </Container>
      </section>
    </>
  );
};

export default Footer;
