import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Footer from '../../components/Footer';

//components

// import SideBar from '../../components/Sidebar';
import NavBar from '../../components/Navbar';
import Category from '../Category';

//pages

import Home from '../Home';
import Product from '../Product';
import Cart from '../Cart';
import Checkout from '../Checkout';
import Account from '../Account';
import CleaningFacility from '../CleaningFacility';
import SearchResults from '../SearchResults';
import Verify from '../Verify';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';

import axios from 'axios';
import More from '../More';
import Login from '../Login';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriberDetailsAsync, logout } from '../../redux/subscriberSlice';
import ErrorPage from '../Error404';
import MobCategories from '../MobCategories';
import Shipping from '../Shipping';

const App = () => {
  const dispatch = useDispatch();
  axios.defaults.baseURL = 'https://backend.shikhartradelink.com';
  // axios.defaults.baseURL = 'http://localhost:5000';

  const { isLoginError } = useSelector((state) => state.subscribers);
  useEffect(() => {
    if (isLoginError) {
      dispatch(logout());
    }
  }, [isLoginError]);
  return (
    <>
      <NavBar />

      <main>
        <section className='contentWrapper'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/category/:slug' element={<Category />} />
            <Route path='/product/:slug' element={<Product />} />
            <Route path='/verify/:id/:token' element={<Verify />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/forgot-password/:token' element={<ResetPassword />} />
            <Route path='/login' element={<Login />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/cart/buy-now' element={<Cart />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path='/shipping' element={<Shipping />} />
            <Route path='/shipping/buy-now' element={<Shipping />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path='/checkout/buy-now' element={<Checkout />} />
            <Route path='/account' element={<Account />} />
            <Route path='/cleaning' element={<CleaningFacility />} />
            <Route path='/search' element={<SearchResults />} />
            <Route path='/mobcat' element={<MobCategories />} />
            <Route path='/more' element={<More />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default App;
